import React from "react";

const Travel = React.lazy(() => import("./pages/Travel/index"));

//Utility
const PagePrivacy = React.lazy(() =>
  import("./pages/Pages/Utility/PagePrivacy")
);
const PageTerms = React.lazy(() => 
  import("./pages/Pages/Utility/PageTerms")
);
const PageThankYou = React.lazy(() => 
  import("./pages/Pages/Special/PageThankYou")
);

//About
const PageAboutUs = React.lazy(() =>
  import("./pages/Pages/About/PageAboutUs")
);


const routes = [
  //routes without Layout

 
  //Index Main
  { path: "/", component: Travel },

  //Utility
  { path: "/terms-and-conditions", component: PageTerms },
  { path: "/privacy-policy", component: PagePrivacy },
  { path: "/thank-you", component: PageThankYou },

   //Utility
   { path: "/about", component: PageAboutUs },
  
];

export default routes;
